<template>
  <div class="up-center">
    <h1 class="up-main-title">Редактировать учебный центр {{ form.name }}</h1>
    <CreateEditForm v-model="form" @save="saveHandler" edit-mode />
    <el-alert class="up-center__alert" type="warning" :closable="false">
      Укажите ниже какие курсы или целые категории курсов доступны данному
      партнеру, после чего нажмите кнопку СОХРАНИТЬ
    </el-alert>
    <CoursesTree :resources="resources" edit-mode ref="courses-tree" />
  </div>
</template>

<script>
import educationalCenterMixin from "@/mixins/admin/newEditEducationalCenter.mixin";

export default {
  name: "ShowEducationalCenter",
  mixins: [educationalCenterMixin],
  data: () => ({
    resources: [],
  }),
  methods: {
    setDefaultData({ partner, resources }) {
      this.form.name = partner.name;
      this.form.phone = partner.phone;
      this.form.email = partner.email;

      if (!resources || resources.length === 0) return;

      this.resources = resources;
    },
    async fetchCenterData() {
      try {
        const { data } = await this.$axios.get(
          `/admin/partner/${this.$route.params.id}`
        );

        this.setDefaultData(data);
      } catch (e) {
        //
      }
    },
    async saveHandler() {
      try {
        const resources = this.collectResourses();

        await this.$axios.put("/admin/partner", {
          partner: {
            id: this.$route.params.id,
            ...this.form,
          },
          resources,
        });

        this.$message({
          message: "Поздравляем! Учебный центр успешно отредактирован.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText(
      `Просмотр учебного центра №${this.$route.params.id}`
    );

    this.fetchCenterData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/educational-center/new-edit.scss";
</style>